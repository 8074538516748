import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Grid, Typography, Breadcrumbs, Button, Paper, styled } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import { isMobile } from 'react-device-detect';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { validateEmail } from '../../utils/util';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
const fileTypes = ['.docs', '.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  '.rtf', "application/pdf"]

const Applyo = () => {
    const location = useLocation();
    const {name} = useParams();
    const [requirement, setRequirement] = useState(-1);
    const [validate, setValidate] = useState({
        bool: false,
        msg: ''
    });
    const pathPart = location.pathname.split("/");
    const navurl = pathPart[pathPart.length-1];
    const [status, setStatus] = useState(false);
    const [file, setFile] = useState(null);

    const requireDetailsArr = useMemo(() => [
        {
            title: 'Planning Engineer',
            id:'planning-engineer',
            content:`Require 4-5 years of experience in MEP services planning. Familiarity with
        project scheduling and coordination is essential.
        <br /><br />
        <span style=font-weight:700>Educational qualifications</span>: M.Tech / M.E. with specialization in Refrigeration / Mechanical /
        Electrical. MBA is desirable, but not mandatory.
        <br /><br />
        <span style=font-weight:700>Additional requirements:</span> You should be able to work with diverse teams, with different skill-sets
        and cultural attributes. Your communication should be above-average to help you connect with
        your teams as well as clients. Familiarity with latest tech developments in your own field, and in
        adjacent fields, plus a propensity to keep learning would be of immense value, since this field is
        constantly evolving. Finally, you should be willing to travel to various locations where projects
        might be located.`},
        {
            title: 'Sales Engineer',
            id:'sales-engineer',
            content: `Minimum 4-5 years of experience in quoting for projects across all services.
            Exposure to design and build projects will be an added advantage. Proficiency in the latest
            relevant software is a must.
            <br /><br />
            <span style=font-weight:700>Educational qualifications:</span> B.Tech / B.E and MBA from a reputed university.
            <br /><br />
            <span style=font-weight:700>Additional requirements:</span> You should have good knowledge of the market and its requirements
            vis-a-vis MEP solutions. Besides, you should have a firm grasp of the latest tech developments
            in your own field, and in adjacent fields. Your communication should be excellent to help you
            connect with customers, and understand their stated needs, as well as be able to glean their
            wish-list even if they are unable to articulate it well. You should be willing to travel to generate
            leads or for follow-up visits.`
        },
        {
            title: 'Project Engineer',
            id: 'project-engineer',
            content: `Seeking young and dynamic individuals with 4-5 years of experience in
            project management. Familiarity with Microsoft Projects will be an added advantage.
            <br /><br />
            <span style=font-weight:700>Educational qualifications:</span> B.Tech / B.E and MBA. A course in project management would be
            highly desirable.<br /><br />
            <span style=font-weight:700>Additional requirements:</span>  You should be able to liaise with diverse teams, with different skill-sets
            and cultural attributes. Your communication should be above-average to help you connect with
            your teams as well as clients. You should be familiar with project management as well as
            managing project exigencies that are bound to rise from time to time. Finally, you should be
            willing to travel to various locations where projects might be located.`
        }
    ], []);
    const callCareer = useCallback(() =>{
        Axios.get(`https://uniqueengineersltd.com/blog/wp-json/wp/v2/career/?slug=${navurl}`)
        .then(res => {
            const {id, title, content} = res.data[0];
            // checkFigureLarge(content.rendered);
            setRequirement({id, title: title.rendered, content: content.rendered});
            console.log('figures', id, title, content);

            // setPostData({id, stitle: title.rendered, featuredMedia: featured_media, jetpack_featured_media_url, content: content.rendered})
        })
    }, [navurl])
    useEffect(()=>{
        callCareer()
    },[callCareer]);
    useEffect (() => {
        const filteredData = requireDetailsArr.find(item => item.id === name);
        // console.log(filteredData);
        if(filteredData !== undefined){
            // setRequirement(filteredData);
        }
    }, [requireDetailsArr, name])

    const mainStyle = {px: !isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}
    const inputStyle = {
        fontFamily:'Inter',
        fontSize:'1em',
        color:'',
        backgroundColor:'#F3F3F3',
        border:'unset',
        height:'3em',
        width:'100%',
        padding: '1em',
        marginBottom: '1.5em'
    }

    const [userInform, setUserInform] = useState({
        username: '',
        usermail: '',
        userphone: '',
        coverletter: '',
        userattached: null
    })
    const handleChange = (event) => {
        // console.log('ee', event.target.files[0]);
        const sfile = event.target.files[0];
        console.log(sfile.type, sfile.size, fileTypes.includes(sfile.type));
        
        setFile(sfile);
      };
    const handleSubmit = (event) => {
        event.preventDefault();
        const { username, usermail, userphone, coverletter} = userInform;
        if(username.trim() === '' ) {
            setValidate({bool:true, msg:'Write full name.'}); 
            return;
        }
        if(userphone.trim() === '') {setValidate({bool:true, msg:'Write correct phone number.'}); return;}
        if(usermail.trim() === '' || !validateEmail(usermail) ) {setValidate({bool:true, msg:'Write correct email.'}); return;}
        if(!file) {setValidate({bool:true, msg:'Resume has not been attached. Please attach it'}); return;}
        console.log('wwwv', file, fileTypes.includes(file.type));
        if(!fileTypes.includes(file.type) ){setValidate({bool:true, msg:'Resume is not doc or pdf. Please attach correct one.'}); return;}
        if(file.size > 3000000 ){setValidate({bool:true, msg:'Resume file size is exceeding 3MB. Please attach smaller file size.'}); return;}
        Axios.post('careermail.php', {name:username, phone:userphone, email:usermail, coverletter, jobtitle: requirement.title})
        .then(res => console.log('res ', res))
        .catch(error => console.log('error ', error))
        
        setStatus(true)
    }
     
    const callUser = (event) =>{
        event.preventDefault();
        setStatus(false);
        const {id, value} = event.target;
        const tempUserInform = {...userInform};
        tempUserInform[id] = value
        setUserInform(tempUserInform);
    }


    return (
        <Grid container xs={12} sx={{width:'100%', justifyContent:'center'}}>
            <Grid item sx={mainStyle}>
                <Grid item sx={{px: !isMobile && 12.5, pt:'1em', width:'100%'}}>
                <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/careers'} className='normal-text title-color' style={{textDecoration:'none'}} >Careers</Link>
                        <Typography className='normal-text' sx={{color:'#535353'}}>Apply Online</Typography>
                    </Breadcrumbs>
                    {/* <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                        <Typography variant='h3' component='h3' className='titleStyle title-color' sx={{pb:2}}>Apply online</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{}}>
                                <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                    We at Unique understand that building services are not just technical aspects of a structure; they are the soul of a building that needs constant attention for the building and its occupants’ well-being. We at Unique understand that building services are not just technical aspects of a structure. Building services are the life force or "prana" of building structures. 
                                </Typography>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item sx={mainStyle}>
                <Grid item xs={12} sx={{px: !isMobile && 12.5, display:'flex', flexFlow:'column', alignItems:'flex-start'}}>
                    <Typography variant='body2' component='h3' className='titleStyle subtitleStyle title-color' sx={{width:'100%', textTransform:'capitalize', textAlign:'left', pb:'2em'}}>{requirement.title}</Typography>
                    <Grid item xs={12}>
                        <Typography variant='body2' component='p' className='normal-text' dangerouslySetInnerHTML={{ __html: requirement.content }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={mainStyle}>
                <Grid item xs={12} sx={{px: !isMobile && 12.5}}>
                    <Typography variant='body2' component='p' className='normal-text'>
                        At Unique Engineers, we offer a collaborative and empowering work environment. Join us to
                        enjoy autonomy in decision-making, opportunities for growth, and the chance to stay updated
                        with the latest industry advancements.
                        To apply, please submit your resume highlighting your relevant experience and qualifications. We look forward to welcoming talented professionals to our team at Unique
                        Engineers.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sx={{...mainStyle, pb: !isMobile ? '5em' : '3em'}}>
                <Grid item xs={12} sm={8} sx={{px: !isMobile && 12.5, pt:'1em'}}>
                <Paper sx={{padding: !isMobile ? '3em' : '2em 1em'}}>
                    <Typography variant='h5' component={'h5'} className='titleStyle title-color' sx={{ pb:'1.5em'}}>Application form</Typography>
                    <form>
                        { !status && <>
                        <input type='text' id='username' name='username' value={userInform.username} placeholder='Full Name' 
                            onChange={callUser} style={inputStyle} />
                        <input type='text' id='usermail' name='usermail' value={userInform.usermail} placeholder='Email Id' 
                            onChange={callUser}  style={inputStyle} />
                        <input type='number' id='userphone' name='userphone' pattern='^[0-9]+$' value={userInform.userphone} placeholder='Phone Number' 
                            onChange={callUser}  style={inputStyle} />
                        <textarea rows='5'  id='coverletter' name='coverletter' value={userInform.coverletter} placeholder='Cover Letter' 
                            onChange={callUser}  style={{...inputStyle, resize:'none', height:'auto'}} />
                        <div>
                            {/* <label for='uploadfile' className='normal-text title-color'>Attach Resume: </label> */}
                            <Button name='uploadfile' id='uploadfile' component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                Attach Resume
                            <VisuallyHiddenInput type="file" 
                                onChange={(e) => handleChange(e)} 
                                accept={fileTypes.toString()} />
                            </Button>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{ fontSize:10, wordWrap:'break-word'}}>(.pdf, .doc, .docx only)</Typography>
                            {file && <Typography variant='body2' component={'p'} sx={{wordWrap:'break-word'}}>{file.name}</Typography>}
                        </div>
                        </> }
                        {validate.bool && !status && <Typography variant='body1' component={'p'} className='normal-text text-color' sx={{py:1}} >{validate.msg}</Typography>}
                        {status && <Typography variant='body2' component={'h4'} className='normal-text text-color' sx={{fontSize:'1.5 !important'}} >Thank you, your reume has been sent. We will be in touch shortly.</Typography>}
                        {!status && <Grid item sx={{display:'flex', columnGap:'0.5em', alignItems:'center', justifyContent:'flex-end', mt:'1em', width:'100%'}}>
                            <Button variant='contained' className='normal-text' 
                                sx={{backgroundColor:'#1D5A93', letterSpacing:'3px', color:'#FFFFFF !important', fontSize:'0.9em !important' }}
                                onClick={handleSubmit}
                            >Submit</Button>
                        </Grid>}
                    </form>
                </Paper>
                </Grid>
            </Grid>
        </Grid>
    )


}

export default Applyo;